import "./utils/localstorageUtility";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import { Worker } from "@react-pdf-viewer/core";
import { createRoot } from "react-dom/client";
//

// import Branch from "branch-sdk";
import { QueryClientProvider } from "react-query";
import "react-query/devtools";
import queryClient from "@/reactQuery/queryClient";
import store from "@/redux/store";
import { getFirebaseInstance } from "@/services/firebase";
import reportWebVitals from "./reportWebVitals";
import "@/assets/fonts/STHeitiSC.woff2";
import "@/assets/fonts/LucidaGrande.woff2";
import "@/assets/fonts/charter-400-normal.woff";
import "@/assets/fonts/charter-700-normal.woff";
import "@/assets/fonts/sohne-400-normal.woff";
import "@/assets/fonts/sohne-500-normal.woff";
import "./index.css";
import App from "./App";
import config from "./config/appConfig";
import ErrorBoundary from "./ErrorBoundary";  // Import the custom error boundary

getFirebaseInstance();
const trackingId = "G-N004V2YVVG";
ReactGA.initialize(trackingId);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <link rel="preconnect" href={config.apiUrl} />
      <Provider store={store}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Worker>
      </Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </ErrorBoundary>
);
reportWebVitals();
